.app-container {
  text-align: center;
  padding: 0;
  border: none;
  margin: 0;
}

.header {
  background-color: #e3dfda;
}

h1, h3 {
  padding: 0;
  border: none;
  margin: 0;
}

.app-title {
  padding: 0.6em;
}

.app-presentation {
  padding-bottom: 0.5em;
}

ul {
    display: flex;
    align-items: stretch; /* Default */
    justify-content: space-evenly;
    width: 100%;
    background: #ffffff;
    margin: 0;
    padding: 0;
}
li {
    display: block;
    flex: 0 1 auto; /* Default */
    list-style-type: none;
}